import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/diarree-bij-kinderen/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/diarrhee-chez-les-enfants/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="5bf9151f200b5c83268035b3fbec40323b22d3bc"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li className="active" id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/diarree-bij-kinderen/"> Diarree bij kinderen</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <div className="widgeinner" >
                <p>
                  Als het gaat om de behandeling van de symptomen van diarree,
                  zijn er nog veel onzekerheden. Lees enkele tips.
                </p>
                </div>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
				<a href="/imodium-behandelen-diarree/">
                <h4>Welke IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Welke IMODIUM®?"
                  />
                </div>
                <div className="parag" style={{"letter-spacing": "0.5px"}}>
                <p>
                  <p>Zoek uit <strong>welke IMODIUM®</strong> het meest geschikt is voor jou.</p>
                </p>
                </div>
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <div className="parag" style={{"letter-spacing": "0.25px"}}>
                <p>
                  Deze video laat zien hoe Imodium<sup>®</sup> helpt om je
                  darmen terug in hun natuurlijke balans te brengen.
                </p></div>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-9">
            <h1>Diarree bij kinderen</h1>
            <div className="img">
              <img
                src="/assets/files/pages/kinder.jpeg"
                width="701"
                height="289"
                alt="Diarree bij kinderen"
              />
            </div>
            <div className="intro row">
              <div class="three columns" style={{
                
              }}>
                         <h2>Wat veroorzaakt diarree bij kinderen?</h2>
              </div>
              <div className="nine columns">
                <p>
                  In de eerste drie jaar komt het gemiddeld één tot tweemaal per
                  jaar voor dat een kind diarree heeft. De hoofdoorzaak zijn
                  virussen. Tegen de bijzonder vaak optredende rotavirussen kan
                  je je kind tegenwoordig laten inenten.
                  <br />
                  Ook voedselintolerantie of -allergie kan{" "}
                  <strong>diarree bij kinderen</strong> veroorzaken. Wanneer
                  diarree geregeld optreedt, raadpleeg dan zeker een arts.
                </p>
              </div>
            </div>
            <p>
              Ongeacht de{" "}
              <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                oorzaak van de diarree
              </a>{" "}
              is het bij kinderen bijzonder belangrijk, deze snel aan te pakken.
              Zeker bij baby’s en jonge kinderen.{" "}
              <strong>Wanneer kinderen diarree hebben</strong>, leidt dit nog
              sneller dan bij een volwassene tot uitdroging. Dit gaat meestal
              gepaard met een verlies aan levensnoodzakelijke elektrolyten.
            </p>
            <h3>
              Hoe behandel ik de symptomen van diarree bij kinderen vanaf 6
              jaar?
            </h3>
            <p>
              Wanneer kinderen diarree hebben, is het in de eerste plaats
              belangrijk om ervoor te zorgen dat er voldoende vocht wordt
              ingenomen. Voor de verdere behandeling van de symptomen van
              diarree bij kinderen vanaf 6 jaar kan je{" "}
              <a href="/imodium-behandelen-diarree/">IMODIUM®</a> zonder
              voorschrift verkrijgen in je&nbsp; apotheek. IMODIUM® vermindert de overmatige darmactiviteit zodat de stoelgang weer vaster wordt en de diarree vermindert. Het verkort
              de duur van de diarree en beschermt dus tegen overmatig verlies
              van water en elektrolyten. Daarnaast zijn er in de apotheek ook
              kant-en-klare elektrolytenmengsels, om het verlies aan mineralen
              en suikers aan te vullen.
            </p>
            <h3>Hoe behandel ik diarree bij kinderen onder 6 jaar?</h3>
            <p>
              Voor kinderen jonger dan 6 jaar raadpleeg je best je arts in geval
              van diarree.
            </p>
            <p>
              De aanvulling van water en elektrolyten met elektrolytenmengsels
              uit de apotheek gebeurt liefst tussen de ‘normale’ maaltijden. Je
              apotheker zal je vertellen hoe je de mengsels moet bereiden en
              toedienen. Eens de oplossing bereid is, bewaar je ze best in de
              frigo. Het is de bedoeling dat je het mengsel verspreid over de
              dag laat opdrinken. Door toevoeging van smaakstoffen (zoals bv een
              beetje cola) zal je kind het makkelijker opdrinken.
            </p>
            <h3>Wanneer moet ik een arts raadplegen?</h3>
            <p>
              Observeer je kind nauwlettend. Bij elke verandering in het gedrag
              van je kind moet je onmiddellijk een arts raadplegen.
            </p>
            <p>Volgende symptomen kunnen wijzen op uitdroging:</p>
            <ul>
              <li>
                Weinig en donker gekleurde urine (controleer de luier bij
                baby’s)
              </li>
              <li>Huilen zonder tranen</li>
              <li>
                Verrimpelde huid die niet meer automatisch in zijn plooi valt
                bij het optrekken ervan.
              </li>
              <li>Ingevallen ogen</li>
              <li>Sufheid</li>
              <li>Droge mond en veel drinken</li>
              <li>
                Ingevallen fontanellen bij pasgeborenen (plaats waar de schedel
                nog niet is dicht gegroeid).
              </li>
            </ul>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                                                
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>

                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
